import React, { Component } from 'react';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import './App.css';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { langActions } from './langs/langActions';
import langConstants from './langs/langConstants';
import usersActions from './modules/Auth/actions/usersActions';
import ConfirmUserPage from './modules/Auth/ConfirmUserPage';
import LoginPage from './modules/Auth/LoginPage';
import ResetPasswordPage from './modules/Auth/ResetPasswordPage';
import ResetPasswordRequestPage from './modules/Auth/ResetPasswordRequestPage';
import SignUpPage from './modules/Auth/SignUpPage';
import DashboardPage from './modules/Dashboard/DashboardPage';
import WizardPage from './modules/Wizard/WizardPage';
import constants from './utils/constants';
import history from './utils/history';
import { langService } from './services/langService';
import DownloadPage from "./modules/Download/DownloadPage";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import appConfig from "./config/appConfig";

interface IAppProps {
    loggedUser: any;
    loadTranslations(lang: string);
}

class App extends Component<IAppProps, any> {

    componentWillMount() {
        const queryLang = (new URLSearchParams(window.location.search)).get('lang');
        const storedLang = langService.getSavedLang();
        const lang = queryLang
            ? queryLang
            : (storedLang ? storedLang : langConstants.DEFAULT_LANGUAGE);
        this.props.loadTranslations(lang);
    }

    componentDidMount() {
        if (appConfig.includeSmartsUpp !== '0') {
            const script = document.createElement("script");
            script.src = '/smartsupp.js';
            document.head.appendChild(script);
        }

        if (appConfig.includeGTM !== '0') {
            const script = document.createElement("script");
            script.src = '/gtm.js';
            document.head.appendChild(script);
        }

        if (appConfig.googleAnalyticsId !== '0') {
            ReactGA.initialize(appConfig.googleAnalyticsId);
            history.listen((location) => {
                ReactGA.pageview(location.pathname + location.search);
            });
        }

        if (appConfig.fbPixelId !== '0') {
            ReactPixel.init(appConfig.fbPixelId);
            history.listen((location) => {
                ReactPixel.pageView();
            });
        }
    }

    render() {
        return (
            <BreadcrumbsProvider>
                <div>
                <ToastContainer transition={Slide} />
                <Router history={history}>
                    <Switch>
                        <Route exact path={constants.ROUTES.AUTH.LOGIN} component={LoginPage} />
                        <Route exact path={constants.ROUTES.AUTH.SIGN_UP} component={SignUpPage} />
                        <Route exact path={constants.ROUTES.AUTH.CONFIRM} component={ConfirmUserPage} />
                        <Route exact path={constants.ROUTES.AUTH.RESET_PASSWORD_REQUEST} component={ResetPasswordRequestPage} />
                        <Route exact path={constants.ROUTES.AUTH.RESET_PASSWORD} component={ResetPasswordPage} />

                        <PrivateRoute exact path={constants.ROUTES.DASHBOARD} component={DashboardPage} />
                        <PrivateRoute exact path={constants.ROUTES.DOWNLOAD} component={DownloadPage} />
                        <PrivateRoute path={constants.ROUTES.WIZARD.PAGE} component={WizardPage} />
                    </Switch>
                </Router>
                </div>
            </BreadcrumbsProvider>
        );
    }
}


const mapStateToProps = (state) => {
	return {
		translations: state.entities.translations,
		loggedUser: state.app.auth.user // getAuthUser(state), - selector
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadTranslations: (lang: string) => {
		    return dispatch(langActions.load(lang));
		},
		loadUser: (userId) => {
			return dispatch(usersActions.loadUser(userId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
